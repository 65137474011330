import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import "./styles/impact.scss";

export const ImpactScholarshipBottomCTA = ({
  image,
  mobileImage,
  altText,
  link,
}) => {
  return (
    <div id="impactScholarshipBottomCTA">
      <Container fluid="lg" className={`pt-5`}>
        <Row className="align-items-center">
          <Col lg={6} xs={12} className="pe-lg-5 mb-4 mb-lg-0">
            <h2 className="mb-4 pb-1">
              Introducing Unique Impact Scholarships
            </h2>
            <p className="mb-4 pb-1">
              Available to Meridian University students worldwide, Meridian
              University's Impact Scholarships represent the University's
              commitment to supporting graduates to take transformative action
              in the fields of Business, Education, Leadership, and Psychology.
            </p>
            <ul className="mb-4 pb-1">
              <li>
                The Meridian <b>Social Entrepreneurship</b> Scholarship
              </li>
              <li>
                The Meridian <b>Developmental Coaching</b> Scholarship
              </li>
              <li>
                The Meridian <b>Collective Trauma Transformation</b> Scholarship
              </li>
            </ul>
            <a href={link} className="btn btn-primary">
              Contact An Advisor{" "}
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                  fill="#FFFFFF"
                />
              </svg>
            </a>
          </Col>
          <Col lg={6} xs={12}>
            <img
              src={`https://web-cdn.meridianuniversity.edu/site-content-images/${image}`}
              alt={altText}
              className="img-fluid d-none d-lg-block"
            />
            <img
              src={`https://web-cdn.meridianuniversity.edu/site-content-images/${mobileImage}`}
              alt={altText}
              className="img-fluid d-lg-none"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const PSPPScholarship = ({ image, mobileImage, altText, link }) => {
  return (
    <div id="impactScholarshipBottomCTA">
      <Container fluid="lg" className={`pt-5`}>
        <Row className="align-items-center">
          <Col lg={6} xs={12} className="pe-lg-5 mb-4 mb-lg-0">
            <h2 className="mb-4 pb-1">
              PSPP Scholarship: Empowering Your Journey
            </h2>
            <p className="mb-4 pb-1">
              Meridian University has now introduced the PSPP Scholarship. This
              scholarship has been launched with the intent to facilitate
              students’ participation in selected PSPs with which Meridian has
              an established partnership. Students enrolled in or having
              completed one of these qualifying PSPPs are eligible to apply for
              the Meridian PSPP Scholarship.
            </p>
            <p className="mb-4 pb-1">
              The PSPP Scholarship can also be acquired by students concurrently
              undertaking a designated PSPP during their coursework at Meridian.
              This innovative scholarship reflects Meridian's commitment to
              making the unique benefits of Professional Specialty Program
              Pathways even more accessible.
            </p>
            <p>
              <a href={link} className="btn btn-primary">
                Contact An Advisor{" "}
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="#FFFFFF"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </a>
            </p>
          </Col>
          <Col lg={6} xs={12}>
            <img
              src={`https://web-cdn.meridianuniversity.edu/site-content-images/${image}`}
              alt={altText}
              className="img-fluid d-none d-lg-block"
            />
            <img
              src={`https://web-cdn.meridianuniversity.edu/site-content-images/${mobileImage}`}
              alt={altText}
              className="img-fluid d-lg-none"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const ImpactScholarshipTopCTA = ({ type, link }) => {
  if (type === "basic") {
    return (
      <div id="impactScholarshipTopCTA">
        <Container fluid="lg" className="py-md-5 py-4 px-md-0 mb-4 mb-lg-5">
          <Row className="align-items-center p-lg-5 p-4 opaque-gray-bg mx-1 mx-md-0">
            <Col lg={6} xs={12} className="pe-lg-5 mb-4 mb-lg-0">
              <Col xs={12} className="d-flex align-items-center mb-3">
                <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1101_2009)">
                      <path
                        d="M4.99979 13.18V15.99C4.99979 16.72 5.39979 17.4 6.03979 17.75L11.0398 20.48C11.6398 20.81 12.3598 20.81 12.9598 20.48L17.9598 17.75C18.5998 17.4 18.9998 16.72 18.9998 15.99V13.18L12.9598 16.48C12.3598 16.81 11.6398 16.81 11.0398 16.48L4.99979 13.18ZM11.0398 3.52003L2.60979 8.12003C1.91979 8.50003 1.91979 9.50003 2.60979 9.88003L11.0398 14.48C11.6398 14.81 12.3598 14.81 12.9598 14.48L20.9998 10.09V16C20.9998 16.55 21.4498 17 21.9998 17C22.5498 17 22.9998 16.55 22.9998 16V9.59003C22.9998 9.22003 22.7998 8.89003 22.4798 8.71003L12.9598 3.52003C12.3598 3.20003 11.6398 3.20003 11.0398 3.52003Z"
                        fill="#0078AE"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1101_2009">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span className="d-inline-flex ms-4">
                  <h3>A Life-Changing Scholarship Opportunity</h3>
                </span>
              </Col>
              <Col xs={12} className="pt-1">
                <p className="mb-4">
                  If you've ruled out a graduate degree for financial reasons,
                  think again. Meridian's Impact Scholarships go beyond what you
                  may have come to expect from financial aid. Apply today and
                  explore how a transformative graduate experience could
                  accelerate your professional goals.
                </p>
                <Link to={link} className="btn btn-primary">
                  Apply for an Impact Scholarship{" "}
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Link>
              </Col>
            </Col>
            <Col lg={6} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/impact-scholarship-top-cta-impact-image.webp"
                alt="A man sits at the end of a dock watching the sunrise over a mountain lake"
                className="img-fluid d-block d-lg-none d-xl-block"
              />
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/impact-scholarship-top-cta-impact-image-desktop.webp"
                alt="A man sits at the end of a dock watching the sunrise over a mountain lake"
                className="img-fluid d-none d-lg-block d-xl-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div id="impactScholarshipTopCTA">
        <Container fluid="lg" className="py-md-5 py-4 px-md-0 mb-4 mb-lg-5">
          <Row className="align-items-center p-lg-5 p-4 opaque-gray-bg mx-1 mx-md-0">
            <Col lg={6} xs={12} className="pe-lg-5 mb-4 mb-lg-0">
              <Col xs={12} className="align-items-center mb-3 pb-1">
                <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.44922 17.25H2.82422C2.61711 17.25 2.44922 17.4179 2.44922 17.625V21.375C2.44922 21.5821 2.61711 21.75 2.82422 21.75H5.44922C5.65633 21.75 5.82422 21.5821 5.82422 21.375V17.625C5.82422 17.4179 5.65633 17.25 5.44922 17.25Z"
                      fill="#0078AE"
                    />
                    <path
                      d="M11.4492 15.375H8.82422C8.61711 15.375 8.44922 15.5429 8.44922 15.75V21.375C8.44922 21.5821 8.61711 21.75 8.82422 21.75H11.4492C11.6563 21.75 11.8242 21.5821 11.8242 21.375V15.75C11.8242 15.5429 11.6563 15.375 11.4492 15.375Z"
                      fill="#0078AE"
                    />
                    <path
                      d="M17.4492 13.125H14.8242C14.6171 13.125 14.4492 13.2929 14.4492 13.5V21.375C14.4492 21.5821 14.6171 21.75 14.8242 21.75H17.4492C17.6563 21.75 17.8242 21.5821 17.8242 21.375V13.5C17.8242 13.2929 17.6563 13.125 17.4492 13.125Z"
                      fill="#0078AE"
                    />
                    <path
                      d="M23.0742 9.375H20.4492C20.2421 9.375 20.0742 9.54289 20.0742 9.75V21.375C20.0742 21.5821 20.2421 21.75 20.4492 21.75H23.0742C23.2813 21.75 23.4492 21.5821 23.4492 21.375V9.75C23.4492 9.54289 23.2813 9.375 23.0742 9.375Z"
                      fill="#0078AE"
                    />
                    <path
                      d="M23.4904 2.0437L18.9491 2.24995C18.877 2.25376 18.8075 2.27832 18.749 2.32067C18.6905 2.36303 18.6455 2.42138 18.6194 2.48871C18.5932 2.55604 18.5871 2.62948 18.6017 2.70022C18.6163 2.77095 18.651 2.83597 18.7016 2.88745L19.6616 3.84745C17.7416 5.74931 15.6739 7.49603 13.4779 9.0712C9.46914 11.9999 2.44914 13.4999 2.37039 13.4999C2.27093 13.5074 2.17851 13.5541 2.11346 13.6297C2.04841 13.7053 2.01606 13.8036 2.02351 13.9031C2.03097 14.0025 2.07764 14.0949 2.15324 14.16C2.22884 14.2251 2.32718 14.2574 2.42664 14.2499C5.80164 14.4562 10.7179 13.7212 14.2429 11.9587C17.1602 10.5418 19.7562 8.54185 21.8704 6.08245L23.0291 7.2412C23.0805 7.29307 23.1458 7.32884 23.2171 7.34414C23.2885 7.35944 23.3627 7.35359 23.4308 7.32732C23.4988 7.30105 23.5578 7.2555 23.6003 7.19624C23.6429 7.13698 23.6672 7.06659 23.6704 6.9937L23.8841 2.4337C23.8863 2.38159 23.8776 2.3296 23.8585 2.28106C23.8394 2.23251 23.8104 2.18849 23.7734 2.15178C23.7363 2.11508 23.692 2.08651 23.6433 2.0679C23.5945 2.04929 23.5425 2.04105 23.4904 2.0437Z"
                      fill="#0078AE"
                    />
                  </svg>
                </div>
              </Col>
              <Col xs={12} className="align-items-center mb-3">
                <span>
                  <h3>
                    A Life-Changing Entrepreneurship Scholarship Opportunity
                  </h3>
                </span>
              </Col>
              <Col xs={12} className="pt-1">
                <p className="mb-4">
                  If you've ruled out an MBA for financial reasons, think again.
                  Meridian's <b>Social Entrepreneurship Scholarship</b> goes
                  beyond what you may have come to expect from financial aid.
                  Apply today and explore how an MBA designed for social impact
                  could accelerate your professional goals.
                </p>
                <Link to={link} className="btn btn-primary">
                  Apply Now{" "}
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Link>
              </Col>
            </Col>
            <Col lg={6} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/impact-scholarship-top-cta-mba-image-xl.webp"
                alt="A man sits at the end of a dock watching the sunrise over a mountain lake"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/impact-scholarship-top-cta-mba-image-desktop.webp"
                alt="A man sits at the end of a dock watching the sunrise over a mountain lake"
                className="img-fluid d-none d-lg-block d-xl-none"
              />
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/impact-scholarship-top-cta-mba-image-mobile.webp"
                alt="A man sits at the end of a dock watching the sunrise over a mountain lake"
                className="img-fluid d-block d-lg-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};
